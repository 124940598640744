import SectionHeading from "../SectionHeading"
import icon1 from "./step1.svg"
import icon2 from "./step2.svg"
import bgDecorarion from "./bgdecoration.svg"
import icon3 from "./step3.svg"

import Step from "./Step"
import "./styles.scss"


const HugeRewards = () => {

  const steps = [
    {
      icon: icon1,
      title: "Step 1 - Get your link",
      body: "Get your referral code Register and copy referral links or QR codes.",
      css: "hugerewards-container__steps__step",
    },
    {
      icon: icon2,
      title: "Step 2 - Send Invitations",
      body: "Share your referral code Invite your friends to register through the link or code.",
      css: "hugerewards-container__steps__step hugerewards-container__steps__step--right",
    },
    {
      icon: icon3,
      title: "Step 3 - Get Rewards",
      body: "Get your referral rewards. Get $8, Give $5 in real-time. Earn unlimited.",
      css: "hugerewards-container__steps__step",
    }
  ]

  return (
    <div className="hugerewards-container">
      <SectionHeading title="Receive Huge Rewards" />

      <div className="hugerewards-container__steps">
        {steps.map((step) => <Step key={Math.random()} step={step} />)}
        <img src={bgDecorarion} alt="decoration"  className="decoration1" />
        <img src={bgDecorarion} alt="decoration"  className="decoration2" />

      </div>
    </div>
  )
}

export default HugeRewards