import bgdecoration from "./bgdecoration.svg"
import arrow from "./arrow.svg"
import "./styles.scss"
import WaitingList from "../WaitingList"

const Hero = () => {
  return (
    <div className="hero-container">
      <h1 className="hero-container__title">
        Your path to prosperity
        starts with $SLAPA.
      </h1>
      <div className="waiting-list">
        <div className="launch">
          <div>
            <div className="title">
              We are Launching Soon
            </div>
          </div>

          <div>
            <p className="text">
              Join Waiting List
            </p>
            <img src={arrow} alt="arrow" />
            <p className="text last-text">
              We have over 1 million
              in our various global
              waiting list
            </p>
          </div>

        </div>

        <WaitingList />
      </div>
      <img src={bgdecoration} alt="background decoration" className="hero-decoration" />
    </div>
  )
}

export default Hero