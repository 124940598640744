import "./App.css";
import Hero from "./components/Hero";
import Nav from "./components/Nav";
import Join from "./components/Join";
import MobileApps from "./components/MobileApps";
import Footer from "./components/Footer";
import HugeRewards from "./components/HugeRewards";
import Visit from './components/Visit/index';

function App() {
  return (
    <div className="App">
      <Nav />
      <Hero />
      <HugeRewards />
      <Join />
      <Visit/>
      <MobileApps />
      <Footer />
    </div>
  );
}

export default App;
