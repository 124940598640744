import iosIcon from "./ios.svg";
import androidIcon from "./android.svg";
import image from "./banking-payment 1.png";
import "./styles.scss";

const MobileApps = () => {
  return (
    <div className="mobile-apps">
      <div className="mobile-apps__download">
        <h2 className="heading">
          Try the latest version <br />
          of our App
        </h2>
        <p className="text">
          We are giving you $10 for downloading and using our app
        </p>
        <a href="_" className="download-button">
          <img src={iosIcon} alt="IOS-icon" className="button-icon" />
          <span className="button-text"> Download our IOS app</span>
        </a>
        <a href="_" className="download-button download-button--secondary">
          <img src={androidIcon} alt="Android-icon" className="button-icon" />
          <span className="button-text">Download out Android App</span>
        </a>
      </div>
      <img
        className="mobile-apps__image"
        src={image}
        alt="mobile app"
      />
    </div>
  );
};

export default MobileApps;
