import { useState } from "react";
import rightArrowIcon from "../../assets/WaitingListIcons/Arrow - Right.svg";
import linkIconDetails from "./linkIcons";

import "./styles.scss";
import WaitingListLinkIcon from "./WaitingListIcon/index";

const WaitingList = () => {
  const [phoneNumber, setPhoneNumber] = useState("");

  const onchagePhoneNumber = (e: any) => {
    setPhoneNumber(e.target.value);
  };
  const formSubmit = (e: any) => {
    e.preventDefault();
    setPhoneNumber("");
  };

  return (
    <div className="waitinglist-container" id="waitlist">
      <div className="waitinglist-container__top">
        <div className="waitinglist-container__top__form">
          <form onSubmit={formSubmit}>
            <input
              type="number"
              placeholder="Enter phone number"
              onChange={onchagePhoneNumber}
              value={phoneNumber}
            />

            <div
              className="waitinglist-container__top__form__submit"
              onClick={formSubmit}
            >
              Send <img src={rightArrowIcon} alt="right-arrow" />
            </div>
            <ul className="waitinglist-container__top__socials">
              {linkIconDetails.map((linkIcon) => {
                return (
                  <WaitingListLinkIcon
                    key={Math.random()}
                    linkIcon={linkIcon}
                  />
                );
              })}
            </ul>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WaitingList;
