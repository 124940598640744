import "./styles.scss"

const SectionHeading = ({ title }) => {
  return (
    <div className="section-heading">
      <p className="section-heading__text">
        {title}
      </p>
      <div className="bar"></div>

    </div>
  )
}

export default SectionHeading