import "./styles.scss";
const Visit = () => {
  return (
    <div className="visit">
      <a href="_" className="link">Visit SLAPA LP Token</a>
    </div>
  );
};

export default Visit;
