import logo from "./logo.svg"
import "./styles.scss"

const Nav = () => {
  return (
    <div className="nav-container">
      <a href="_">
        <img src={logo} alt="logo" className="nav-container__logo" />
      </a>
    </div>
  )
}

export default Nav