const Step = ({ step }: any) => {
  const { icon, title, body, css } = step;
  return (
    <div className={css}> 
      <img
        src={icon}
        alt={title}
        className="hugerewards-container__steps__step__title-icon"
      />
      <h1 className="hugerewards-container__steps__step__title">{title}</h1>
      <p className="hugerewards-container__steps__step__body">{body}</p>
    </div>
  );
};

export default Step;
